import React from 'react'

export default function projects() {
    return (
        <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
            <span className="heading-meta">My Work</span>
            <h2 className="colorlib-heading animate-box">Recent Projects</h2>
        </div>
    )
}

import React from 'react'

export default function projects() {
    return (
        <div className="col-md-4 animate-box" data-animate-effect="fadeInTop">
        <div className="project" style={{backgroundImage: 'url(images/gsa-2.png)'}}>
            <div className="desc">
                <div className="con">
                    <h3><a href="https://www.loags.com/portfolio/portfolio/student-government-service-design/" target='blank' rel='nofollow noopener' >Student Gov</a></h3>
                    <span>Product & Service Design</span>
                </div>
            </div>
        </div>
        </div>
    )
}

import React, { Component } from 'react'

export default class About extends Component {
    render() {
        return (
        <div>
            <section className="colorlib-about" data-section="about">
            <div className="colorlib-narrow-content">
                <div className="row">
                <div className="col-md-12">
                    <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                    <div className="col-md-12">
                        <div className="about-desc">
                        <span className="heading-meta">What I do</span>
                        <h2 className="colorlib-heading">Writer / Designer / Developer</h2>
                        <p>
                            I love to design and make things; Websites, Voice apps & interfaces, Product presentations and Packaging.
                        </p>
                        <p>
                            Skilled in Visual Design, Interaction Design, User Interfaces, Web Programming, and Research – My languages are HTML, CSS, Python and Javascript (node/ReactJS). I have been designing for 3 years (graphic/layout), developing for 1 year (Front-End/UI and Back End), and have written and developed custom content for 3 years going. As a designer, I am proficient with Wordpress, Voiceflow, Figma, InDesign and Illustrator.
                        </p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
            <section className="colorlib-about">
            <div className="colorlib-narrow-content">
                <div className="row">
                <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                    <span className="heading-meta">Specialities</span>
                    <h2 className="colorlib-heading">I live in digital space.</h2>
                </div>
                </div>
                <div className="row row-pt-md">
                
                
                <div className="col-md-4 text-center animate-box">
                    <div className="services color-1">
                    <span className="icon">
                        <i className="icon-globe" />
                    </span>
                    <div className="desc">
                        <h3>Web Development </h3>
                        <p>I build websites and web apps using Python, JavaScript, React, HTML, and CSS</p>
                    </div>
                    </div>
                </div>
                <div className="col-md-4 text-center animate-box">
                    <div className="services color-3">
                    <span className="icon">
                        <i className="icon-bulb" />
                    </span>
                    <div className="desc">
                        <h3>Product & Service Design</h3>
                        <p>I love big-picture thinking. But I'll equally drill down into every single piece of interaction I can handle.</p>
                    </div>
                    </div>
                </div>
                <div className="col-md-4 text-center animate-box">
                    <div className="services color-5">
                    <span className="icon">
                        <i className="icon-layers2" />
                    </span>
                    <div className="desc">
                        <h3>Voice Skills (Alexa and Google)</h3>
                        <p>Interaction design, content strategy, plus a little bit of backend.. I couldn't have asked for more!</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        </div>
        )
    }
}

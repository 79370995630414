import React from 'react'
import ReactHtmlParser from 'react-html-parser';


export default function TimelineArticles(props) {
    return (
        <article className="timeline-entry animate-box" 
        data-animate-effect={ props.content.style.fadeIntype }>
            <div className="timeline-entry-inner">
                <div className={ props.content.style.iconType }>
                <i className="icon-pen2" />
                </div>
                <div className="timeline-label">
                <h2>{ props.content.header } <span> {props.content.date} </span></h2>
                <p>{ props.content.description }</p>
                <p>{ ReactHtmlParser(props.content.misc)  }</p>
                </div>
            </div>
        </article>
    )
}

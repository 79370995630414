import React from 'react'

export default function Project2() {
    return (
        <div className="col-md-4 animate-box" data-animate-effect="fadeInRight">
            <div className="project" style={{backgroundImage: 'url(images/pstg-1.jpg)'}}>
                <div className="desc">
                    <div className="con">
                        <h3><a href="https://studio.wastedinkspace.com/portfolio/positive-solutions-to-greatness-foundation/" target='blank' rel='nofollow noopener' >PSTG Foundation</a></h3>
                        <span>Web Design & Development</span>
                    </div>
                </div>
            </div>
        </div>
    )
}



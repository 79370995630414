const content = [
    {
        id: "1",
        header: "Design Generalist at WastedInkSpace",
        date: "2020-present",
        style: {
            fadeInType: "fadeInLeft",
            iconType: "timeline-icon color-3"
        },
        description: "Currently, I am creating and designing for the digital space at Wastedinkspace. Also learning new skills!",
        misc: "<p>LINK design folio: <a href='http://studio.wastedinkspace.com/' target='blank' rel='noopener noreferrer''> design.loluaguda.com </a></p>"
    },
    {
        id: "2",
        header: "Masters Candidate at NJIT",
        date: "2019-2021(est.)",
        style: {
            fadeInType: "fadeInTop",
            iconType: "timeline-icon color-1"
        },
        description: "I am studying business, management, and information systems at the graduate level. My goal is to grow my technical skills as a dev engineer while I learn the exciting philosophies surrounding business and team leadership. In my first semester, I helped lead a major rebranding of the Graduate Student Association that resulted in a year-end school award as Organization of The Year.",
        misc: ""
    },
    {
        id: "3",
        header: "Head Designer at The Vector Newspaper",
        date: "2018-2019",
        style: {
            fadeInType: "fadeInLeft",
            iconType: "timeline-icon color-5"
        },
        description: "I collaborated with writers, photographers, and designers to produce a weekly newspaper. Developed print design templates, designed information architectures, and implemented new workflow processes to improve production efficiency and timeliness. I also wrote articles and interviewed many a professor.",
        misc: "<p>LINK 2018/19 Issues: <a href='https://issuu.com/thenjitvector/stacks/c2108f3261c249bc802afe26eeb3149b' target='blank' rel='noopener noreferrer'> issuu.com/thenjitvector </a>  <br> LINK My articles: <a href='http://muckrack.loluaguda.com/' target='blank' rel='noopener noreferrer'> muckrack.loluaguda.com</a> </p>"
    },

    {
        id: "4",
        header: "Research Assistant at NJIT School of Engineering",
        date: "2016-2019",
        style: {
            fadeInType: "fadeInTop",
            iconType: "timeline-icon color-4"
        },
        description: "As a Research Assistant, I researched IoT applications in Pavement Management Systems (PMS). Designed, produced and tested concrete and flowable-fill mix designs, and  wrote literature reviews for research proposals. As an Independent Investigator, I explored the development of a sustainable building product through new techniques for applying Recycled Aggregate Concrete (RAC) as base material. Most Recently, I worked as a data collector in a seatbelt usage study.",
        misc: ""
    },
    {
        id: "5",
        header: "Principal Creative at Loags Media",
        date: "Founded 2016",
        style: {
            fadeInType: "fadeInLeft",
            iconType: "timeline-icon color-2"
        },
        description: "I started Loags Media to serve as a home for my creative work. Today, Loags Media offerings range from art, photography, and poetry content to custom production, strategy, and design services.",
        misc: "<p>LINK Product folio: <a href='http://www.loagsmedia.com/' target='blank' rel='noopener noreferrer''> loagsmedia.com </a></p>"
    },
]

export default content
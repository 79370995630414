import React from 'react'
import RecentProjects from './RecentProjects'
import Subheader from './Subheader'


export default function Projects() {
	return (
	<section className="colorlib-work" data-section="projects">
		<div className="colorlib-narrow-content">
			<div className="row">
				<Subheader />
			</div>
			<div className="row">
				<RecentProjects />
			</div>
		</div>
	</section>
	)
}
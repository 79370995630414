import React from 'react';

import TimelineHeader from './TimelineHeader'
import TimelineBody from "./TimelineBody"


export default function Timeline() {
  return (
    <div>
      <section className="colorlib-experience" data-section="timeline">
        <div className="colorlib-narrow-content">
          <TimelineHeader />
          <div className="row">
            <div className="col-md-12">
              <div className="timeline-centered">
                <TimelineBody />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

import React from 'react'


export default function TimelineHeader() {
    return (
        <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
            <span className="heading-meta">recent highlights</span>
            <h2 className="colorlib-heading animate-box">Timeline</h2>
            </div>
        </div>
    )
}

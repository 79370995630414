import React from 'react';

import TimelineArticles from "./TimelineArticles"
import content from './timeline-data'


export default function TimelineBody() {

const timelineArticles = content.map(item => <TimelineArticles key={item.id} content={item}/>)

return (
    <div>
        { timelineArticles }
        <article className="timeline-entry begin animate-box" data-animate-effect="fadeInBottom">
            <div className="timeline-entry-inner">
                <div className="timeline-icon color-none"></div>
            </div>
        </article>
    </div>
)
}



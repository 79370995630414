import React from 'react'

export default function project1() {
    return (
        <div className="col-md-4 animate-box" data-animate-effect="fadeInLeft">
        <div className="project" style={{backgroundImage: 'url(images/news-1.jpg)'}}>
            <div className="desc">
                <div className="con">
                    <h3><a href="https://studio.wastedinkspace.com/portfolio/community-news-voice-app/" target='blank' rel='nofollow noopener' >Community News Feed</a></h3>
                    <span>Voice Design & Development</span>
                </div>
            </div>
        </div>
        </div>
    )
}

import React from 'react'
import Project1 from './project1'
import Project2 from './project2'
import Project3 from './project3'
// import Project4 from './project4'
// import Project5 from './project5'
// import Project6 from './project6'


export default function Projects() {
    return (
        <>
        <Project1 />
        <Project2 />
        <Project3 />
        {/* <Project4 />
        <Project5 />
        <Project6 /> */}
        </>
    )
}
